
    import {Prop, Vue, Watch} from 'vue-property-decorator';
    import Component from 'vue-class-component';
    // @ts-ignore FIXME there is no @types/vue2-timeago
    import TimeAgo from 'vue2-timeago';

    import DeFragment from '@/components/DeFragment.vue';
    import {FrontendErratum} from '@/erratum.ts';
    import TransitionExpandHeight from '@/components/TransitionExpandHeight.vue';
    import {labelForErratumType, labelForSupportTypeShort, labelForSupportTypeLong} from '@/tools';



    @Component({
        components: {
          TransitionExpandHeight,
          TimeAgo,
          DeFragment,
        }
    })
    export default class Erratum extends Vue {
        @Prop() erratum!: FrontendErratum;
        @Prop() initiallyOpen!: boolean;
        consciousOpen: boolean = false;
        consciousChoiceMade: boolean = false;

        get open() {
            if (this.consciousChoiceMade) {
                return this.consciousOpen;
            } else {
                return this.initiallyOpen;
            }
        }

        get to() {
            return `/?erratum=${this.erratum.id}`;
        }

        get title() {
            return labelForErratumType(this.erratum.type);
        }

        get release() {
            return this.erratum.release + ' ' + labelForSupportTypeShort(this.erratum.support);
        }

        get support_long() {
            return labelForSupportTypeLong(this.erratum.support);
        }

        @Watch('initiallyOpen')
        initiallyOpenChanged() {
            this.consciousChoiceMade = false;
        }

        toggleOpen() {
            this.consciousOpen = !this.open;
            this.consciousChoiceMade = true;
            this.$emit('togglechoice', this.erratum.id, this.open);
        }
    }
