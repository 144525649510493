import {
    Erratum as BackendErratum,
    ErratumType,
    FragmentedString, Id,
    Link,
    MajorMinor,
    MajorMinorPatch,
    Reference,
    SupportType
} from '@/errata-x.x.schema.d.ts';
import {SimplifiedFragmentedString} from '@/types.d.ts';
// @ts-ignore FIXME
import {VERSION_REGEX} from '@/views/ErrataOverview.vue';


export interface FrontendErratum {
    id: string; // v-for :key

    // header
    type: ErratumType; // icon
    support: SupportType; // icon
    number: number;
    src: string;
    release: string;

    // body
    header: SimplifiedFragmentedString;
    issues: SimplifiedFragmentedString[];
    tail: SimplifiedFragmentedString;
    fix: string;
    date: string;
    bug: Link[];
    cve: Link[];

    // filtering
    search__header: string;
    search__issues: string[];
    search__tail: string;
    releases: MajorMinorPatch[];
    majorMinorRelease: MajorMinor;
}

export function toFrontendErratum(backendErratum: BackendErratum): FrontendErratum {
    return {
        id: id(backendErratum.id),

        type: backendErratum.type,
        support: backendErratum.support,
        number: backendErratum.number,
        src: backendErratum.src,
        release: release(backendErratum.releases),

        header: header(backendErratum.header),
        issues: backendErratum.issues.map(v => simplifyFragmentedString(v)),
        tail: simplifyFragmentedString(backendErratum.tail),
        fix: backendErratum.fix,
        date: backendErratum.date,
        bug: backendErratum.bug,
        cve: backendErratum.cve,

        search__header: toNonHTMLString(backendErratum.header),
        search__issues: backendErratum.issues.map(v => toNonHTMLString(v)),
        search__tail: toNonHTMLString(backendErratum.tail),
        releases: backendErratum.releases,
        majorMinorRelease: backendErratum.majorMinorRelease,
    };
}

function simplifyFragmentedString(fragmentedString: FragmentedString): SimplifiedFragmentedString {
    const newFragmentedString: SimplifiedFragmentedString = [];
    let _string = '';
    for (const fragment of fragmentedString) {
        if (typeof fragment === 'string') {
            _string += fragment;
        } else if ((fragment as Link).href) {
            _string += toHTMLString(fragment as Link);
        } else if ((fragment as Reference).erratum) {
            let reference = fragment as Reference;
            reference.erratum = id(reference.erratum);
            newFragmentedString.push(_string);
            newFragmentedString.push(reference);
            _string = '';
        } else {
            // in case the fragment.href or fragment.erratum is empty
            // just render it as text
            _string += fragment.text;
        }
    }
    if (_string) {
        newFragmentedString.push(_string);
    }
    return newFragmentedString;
}

function toNonHTMLString(fragmentedString: FragmentedString): string {
    let _string = '';
    for (const fragment of fragmentedString) {
        if (typeof fragment === 'string') {
            _string += fragment;
        } else {
            _string += fragment.text;
        }
    }
    return _string;
}

function toHTMLString(link: Link): string {
    return `<a href="${link.href}">${link.text}</a>`;
}

function release(releases: MajorMinorPatch[]): string {
    let release = releases[0];
    if (releases.length > 1) {
        const lastRelease = releases[releases.length - 1];
        const match = VERSION_REGEX.exec(lastRelease);
        if (match) {
            const patchLevel = match[3];
            release += `..${patchLevel}`;
        }
    }
    return release;
}

function header(header: FragmentedString): SimplifiedFragmentedString {
    return simplifyFragmentedString(header);
}


function id(id: Id) {
    // TODO move this to the backend(?)
    // why?: '+' is reserved in url, '+' would have to be encoded and that is less readable
    return id.replace('+', 'x');
}
