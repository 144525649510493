
    import {Vue} from 'vue-property-decorator';
    import Component from 'vue-class-component';


    @Component
    export default class TransitionExpandHeight extends Vue {
        beforeEnter(el: HTMLElement) {
            el.style.height = '0';
        }

        enter(el: HTMLElement) {
            el.style.height = `${el.scrollHeight}px`;
        }

        afterEnter(el: HTMLElement) {
             el.style.height = '';
        }

        beforeLeave(el: HTMLElement) {
          el.style.height = `${el.scrollHeight}px`;
        }

        leave(el: HTMLElement) {
            // FIXME/INVESTIGATE for the leave transition we have to use requestAnimationFrame (not quite sure why)
            // in firefox the leave transition does not play with only one requestAnimationFrame
            requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                el.style.height = '0';
            });
            });
        }

        afterLeave(el: HTMLElement) {
            el.style.height = '';
        }
    }
