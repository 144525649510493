import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=c52171c8&"
import script from "./Pagination.vue?vue&type=script&lang=ts&"
export * from "./Pagination.vue?vue&type=script&lang=ts&"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=c52171c8&prod&module=true&lang=stylus&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports