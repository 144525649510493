
    import {Location, RawLocation, Route} from 'vue-router';
    import {Vue, Watch} from 'vue-property-decorator';
    import Component from 'vue-class-component';
    import Multiselect from 'vue-multiselect';
    import Mark from 'mark.js';

    import {
        VersionEntry,
        TypeEntry,
    } from '@/types.d.ts';
    import ErratumComp from '@/components/Erratum.vue';
    import TransitionExpandHeight from '@/components/TransitionExpandHeight.vue';
    import Pagination from '@/components/Pagination.vue';
    import {FrontendErratum} from '@/erratum.ts';
    import {Debounce, labelForErratumType} from '@/tools.ts';
    import debounce from 'lodash.debounce';
    import {ErrataSchema} from '@/errata.schema';
    import {
        Erratum as BackendErratum,
        ErratumType,
        Id
    } from '@/errata-x.x.schema';
    import {toFrontendErratum} from '@/erratum';


    const QUERY_VERSION = 'version';
    const QUERY_PACKAGE = 'package';
    const QUERY_TYPE = 'type';
    const QUERY_FROM = 'fromonward';
    const QUERY_SEARCH = 'search';
    const QUERY_ERRATUM = 'erratum';

    export const VERSION_REGEX = /([0-9]+)\.([0-9]+|x)(?:-([0-9]+|x))?/;

    interface DownloadedErrata {
        [index: string]: FrontendErratum[]
    }

    @Component({
        components: {Multiselect, ErratumComp, Pagination, TransitionExpandHeight},
    })
    export default class ErrataOverview extends Vue {
        downloadedErrata: DownloadedErrata = {};
        availableVersions: VersionEntry[] = [];
        selectedVersions: VersionEntry[] = [];
        availablePackages: string[] = [];
        selectedPackages: string[] = [];
        availableTypes: TypeEntry[] = [];
        selectedTypes: TypeEntry[] = [];
        selectedErrataIds: Id[] = [];
        showStartingFromErratumInput: boolean = false;
        startingFromErratum: any = 0;
        currentPage: number = 1;
        pageSize: number = 50;
        freeSearch: string = '';
        allErrataOpen: boolean = false;
        toggleChoices: string[] = [];
        lastOverviewRoute: string = '/';
        errataNodeMark!: Mark;

        windowWidth: number = 0;

        get showSearchFormToggle() {
            return this.windowWidth < 890;
        }

        get emptyErrataListMessage(): string | null {
            if (this.errata.length > 0 && this.pagedErrata.length === 0) {
                return 'No Errata found which match the search criteria';
            }
            return null;
        }

        searchFormExpandedConsciousChoice: boolean = false;
        get searchFormExpanded() {
            return this.showSearchFormToggle ? this.searchFormExpandedConsciousChoice : true;
        }

        @Debounce(400)
        onFreeSearchInput(evt: any /* FIXME event type*/) {
            this.freeSearch = evt.target.value;
        }

        @Debounce(400)
        onStartingFromErratumInput(evt: any /* FIXME event type*/) {
            this.startingFromErratum = evt.target.value;
        }

        @Debounce(0)
        handleWindowResize() {
            this._handleWindowResize();
        }

        _handleWindowResize() {
            this.windowWidth = window.innerWidth;
        }

        // vue component lifecycle hook
        mounted() {
            this.errataNodeMark = new Mark(this.$refs.errataNode as HTMLElement);
            window.addEventListener('resize', this.handleWindowResize);
            this._handleWindowResize();

            // adjust padding of main when to size of the 'position: fixed' navbar changes
            const navNode = document.getElementById('nav') as HTMLElement;
            const mainNode = document.getElementById('main') as HTMLElement;
            function handleNavbarResize() {
              const rect = navNode.getBoundingClientRect();
              mainNode.style.paddingTop = `${rect.height}px`;
            }
            const debounced = debounce(handleNavbarResize, 200, {
              leading: true,
            });
            // @ts-ignore FIXME
            new ResizeObserver(debounced).observe(navNode);
        }

        // vue component lifecycle hook
        updated() {
            this.errataNodeMark.unmark();
            this.errataNodeMark.mark(this.freeSearchLowercase, {
                acrossElements: true,
            });
        }

        get showPagination() {
            return this.totalItems >= this.pageSize;
        }

        allErrataOpenLastConsciousChoice: boolean = false;
        toggleErrataOpenness() {
            this.allErrataOpen = !this.allErrataOpen;
            this.allErrataOpenLastConsciousChoice = this.allErrataOpen;
            if (!this.allErrataOpen) {
                this.toggleChoices = [];
            }
        }

        onToggleChoice(erratumId: string, open: boolean) {
            if (open) {
                // TODO can this case even occur? better save then sorry i guess (for now at least)
                if (!this.toggleChoices.includes(erratumId)) {
                    this.toggleChoices.push(erratumId);
                }
            } else {
                this.toggleChoices = this.toggleChoices.filter(c => c !== erratumId);
            }
        }

        get freeSearchLowercase(): string {
            return this.freeSearch.toLowerCase();
        }

        get totalItems(): number {
            return this.filteredErrata.length;
        }

        get startingFromErratumSanitized(): number {
            const parsed = Number.parseInt(this.startingFromErratum, 10);
            return Number.isNaN(parsed) ? 0 : parsed;
        }

        get errata(): FrontendErratum[] {
            const errata: FrontendErratum[] = [];
            for (const majorMinor of this.majorMinorInOrder) {
                const _errata = this.downloadedErrata[majorMinor];
                if (_errata) {
                    errata.push(..._errata);
                }
            }
            return errata;
        }

        get filteredErrata(): FrontendErratum[] {
            if (this.selectedErrata.length) {
                return this.selectedErrata;
            }
            return this.errata.filter(erratum =>
                (
                    this.selectedVersions.length === 0
                    || this.selectedVersions.some(sv => erratum.releases.some(ev => ev.startsWith(sv.startsWith)))
                )
                && (
                    this.selectedPackages.length === 0
                    || this.selectedPackages.includes(erratum.src)
                )
                && (
                    this.selectedTypes.length === 0
                    || this.selectedTypes.some(v => v.id === erratum.type)
                )
                && erratum.number >= this.startingFromErratumSanitized
                && (
                    [
                        erratum.number.toString(),
                        erratum.src,
                        erratum.release,
                        erratum.search__header,
                        erratum.search__tail,
                        erratum.fix,
                        erratum.date,
                    ].some(v => v.toLowerCase().includes(this.freeSearchLowercase))
                    || erratum.search__issues.some(v => v.toLowerCase().includes(this.freeSearchLowercase))
                    || erratum.bug.some(v => v.text.toLowerCase().includes(this.freeSearchLowercase))
                    || erratum.cve.some(v => v.text.toLowerCase().includes(this.freeSearchLowercase))
                )
            );
        }

        get pagedErrata() {
            const currentPage = this.currentPage - 1; // page index of Pagination.vue starts at 1
            const startIdx = currentPage * this.pageSize;
            const endIdx = startIdx + this.pageSize;
            return this.filteredErrata.slice(startIdx, endIdx);
        }

        @Watch('filteredErrata')
        filteredErrataChanged() {
            this.currentPage = 1;
            window.scrollTo(0, 0);
        }

        @Watch('selectedVersions')
        selectedVersionsChanged(newVersions: VersionEntry[]) {
            let versions: string[] = [];
            if (this.selectedVersions.length) {
                versions = this.selectedVersions.map(v => v.label);
            } else if (!this.selectedErrataIds.length) {
                // if no versions are selected load all available errata
                // but only if no single errata are selected
                versions = this.availableVersions.map(v => v.label);
            }
            this.fetchErrataJSONs(versions);
        }

        selectedErrataFetchId: number = 0;
        selectedErrata: FrontendErratum[] = [];
        @Watch('selectedErrataIds')
        selectedErrataIdsChanged() {
            // update errata openness
            if (this.selectedErrataIds.length) {
                this.allErrataOpen = true;
            } else {
                this.allErrataOpen = this.allErrataOpenLastConsciousChoice;
            }

            // download selected errata
            this.selectedErrataFetchId = this.selectedErrataFetchId + 1;
            const localFetchId = this.selectedErrataFetchId;
            this.selectedErrata = [];
            for (let x = 0; x < this.selectedErrataIds.length; x++) {
                // TODO handle unexpected formats?
                const [majorMinor, number] = this.selectedErrataIds[x].split('x');

                const eid = this.selectedErrataIds[x];
                this.$set(this.selectedErrata, x, {
                    id: `Loading erratum ${eid}`,
                    notErratum: true
                });
                this.fetchErrataJSON(majorMinor)
                    .then(errata => {
                        if (localFetchId !== this.selectedErrataFetchId) {
                            return;
                        }
                        const erratum = errata?.find(erratum => erratum.id === eid);
                        if (erratum) {
                            this.$set(this.selectedErrata, x, erratum);
                        } else {
                            this.$set(this.selectedErrata, x, {
                                id: `Erratum ${eid} does not exist`,
                                notErratum: true
                            });
                        }
                    })
                    .catch(err => {
                        if (localFetchId !== this.selectedErrataFetchId) {
                            return;
                        }
                        this.$set(this.selectedErrata, x, {
                            id: `Erratum ${eid} does not exist`,
                            notErratum: true
                        });
                    });
            }
        }

        @Watch('freeSearch')
        freeSearchChanged(newValue: string) {
            if (newValue) {
                this.allErrataOpen = true;
            } else {
                this.allErrataOpen = this.allErrataOpenLastConsciousChoice ? this.allErrataOpen : false;
            }
        }

        async fetchMetaData() {
            const metaDataAlreadyLoaded = this.availableVersions.length;
            if (metaDataAlreadyLoaded) {
                return;
            }
            try {
                const response = await fetch('./errata.json');
                let metaData = await response.json();
                this.setMetaData(metaData);
            } catch (err) {
                // TODO Fix fetching of files not failing when file does not exist.
                //      Instead the vue router servers the '*' route
                // TODO error handling
                console.log('Error in fetchMetaData(): ', err);
                throw(err);
            }
        }

        setMetaData(metaData: ErrataSchema) {
            this.availableVersions = metaData.availableVersions;
            this.availablePackages = metaData.availablePackages;
            this.availableTypes = metaData.availableTypes.map(id => ({
                id,
                label: labelForErratumType(id as ErratumType),
            }));
        }

        get majorMinorInOrder() {
            // depends on the fact that the version in 'errata.json' are in order
            return this.availableVersions
                .map(v => v.label)
                .filter(v => !v.includes('x'))
                .reduce((versions: string[], majorMinorPatch) => {
                    const match = VERSION_REGEX.exec(majorMinorPatch);
                    if (match) {
                        const major = match[1];
                        const minor = match[2];
                        const version = `${major}.${minor}`;
                        if (!versions.includes(version)) {
                            versions.push(version);
                        }
                    }
                    return versions;
                }, []);
        }

        fetchErrataJSONs(versions: string[]) {
            const majorMinors: string[] = [];
            for (const version of versions) {
                const match = VERSION_REGEX.exec(version);
                if (match) {
                    const major = match[1];
                    const minor = match[2];
                    const majorAndMaybeMinor = minor === 'x' ? major : `${major}.${minor}`;
                    const toFetch = this.majorMinorInOrder.filter(v => v.startsWith(majorAndMaybeMinor));
                    for (const majorMinor of toFetch) {
                        if (!majorMinors.includes(majorMinor)) {
                            majorMinors.push(majorMinor);
                        }
                    }
                }
            }
            for (const majorMinor of majorMinors) {
                this.fetchErrataJSON(majorMinor)
            }
        }

        async fetchErrataJSON(majorMinor: string): Promise<FrontendErratum[] | null> {
            if (this.downloadedErrata[majorMinor]) {
                return this.downloadedErrata[majorMinor];
            }

            let errata: FrontendErratum[] | null = null;
            try {
                const response = await fetch(`./errata-${majorMinor}.json`);
                const backendErrata: BackendErratum[] = await response.json();
                errata = backendErrata.map(v => toFrontendErratum(v));
                this.$set(this.downloadedErrata, majorMinor, errata);
            } catch(err) {
                // TODO Fix fetching of files not failing when file does not exist.
                //      Instead the vue router serves the '*' route.
                // TODO error handling
                console.log('Error in fetchErrataJSON(): ', err);
                throw(err);
            }
            return errata;
        }

        // vue component lifecycle hook
        async created() {
            await this.fetchMetaData();
            this.routeToState(this.$route, true);
            this.initialRouteToStateDone = true;
        }

        //// state-to-route and route-to-state handling
        skipStateToRoute: boolean = false;
        skipRouteToState: boolean = false;
        initialRouteToStateDone: boolean = false;

        beforeRouteUpdate(
            to: Route,
            from: Route,
            next: (to?: (RawLocation | false | ((vm: Vue) => void))
        ) => void): void {
            const fromOverviewToSingleErratum = !!to.query[QUERY_ERRATUM] && !from.query[QUERY_ERRATUM];
            if (fromOverviewToSingleErratum) {
                this.lastOverviewRoute = from.fullPath;
            }
            this.routeToState(to);
            next();
        }

        // vue component lifecycle hook
        beforeUpdate() {
            this.stateToRoute();
        }

        @Debounce(50)
        stateToRoute() {
            if (!this.initialRouteToStateDone) {
                return;
            }

            if (this.skipStateToRoute) {
                this.skipStateToRoute = false;
                return;
            }
            this.skipRouteToState = true;

            const query: any = {};

            const versions = this.selectedVersions.map(v => v.label);
            if (versions.length) {
                query[QUERY_VERSION] = versions.length === 1 ? versions[0] : versions;
            }

            const packages = this.selectedPackages;
            if (packages.length) {
                query[QUERY_PACKAGE] = packages.length === 1 ? packages[0] : packages;
            }

            const types = this.selectedTypes.map(t => t.id);
            if (types.length) {
                query[QUERY_TYPE] = types.length === 1 ? types[0] : types;
            }

            if (this.startingFromErratumSanitized > 0) {
                query[QUERY_FROM] = this.startingFromErratumSanitized;
            }

            if (this.freeSearch !== '') {
                query[QUERY_SEARCH] = this.freeSearch;
            }

            const errataIds = this.selectedErrataIds;
            if (errataIds.length) {
                query[QUERY_ERRATUM] = errataIds.length === 1 ? errataIds[0] : errataIds;
            }

            // if (!isEqual(query, this.$route.query)) {
            // let replace = !this.$route.query[QUERY_VERSION];
            let promise: Promise<Route>;
            // if (replace) {
                promise = this.$router.replace({query})
            // } else {
            //     promise = this.$router.push({query})
            // }
            promise.catch(err => {
                this.skipRouteToState = false;
                if (err.name !== 'NavigationDuplicated') {
                    throw(err);
                }
            });
            // }
        }

        routeToState(route: Route, addSelectedVersionsFallbackIfApplicable = false) {
            if (this.skipRouteToState) {
                this.skipRouteToState = false;
                return;
            }
            this.skipStateToRoute = true;

            this.selectedVersions = [];
            this.selectedPackages = [];
            this.selectedTypes = [];
            this.selectedErrataIds = [];
            this.startingFromErratum = 0;
            this.freeSearch = '';

            for (const [key, value_] of Object.entries(route.query)) {
                const value = (Array.isArray(value_) ? value_ : [value_])
                    .filter(v => v !== null) as string[];
                switch (key) {
                    case QUERY_VERSION: {
                        this.selectedVersions = value
                            .map(label => this.availableVersions.find(av => av.label === label))
                            .filter(av => av !== undefined) as VersionEntry[];
                        break;
                    }
                    case QUERY_PACKAGE: {
                        this.selectedPackages = value
                            .filter(v => this.availablePackages.includes(v));
                        break;
                    }
                    case QUERY_TYPE: {
                        this.selectedTypes = value
                            .map(id => this.availableTypes.find(at => at.id === id))
                            .filter(at => at !== undefined) as TypeEntry[];
                        break;
                    }
                    case QUERY_FROM: {
                        const f = parseInt(value[0], 10);
                        if (Number.isInteger(f) && f >= 0) {
                            this.startingFromErratum = f;
                            this.showStartingFromErratumInput = true;
                        }
                        break;
                    }
                    case QUERY_SEARCH: {
                        this.freeSearch = `${value[0]}`;
                        break;
                    }
                    case QUERY_ERRATUM: {
                        this.selectedErrataIds = value;
                        break;
                    }
                }
            }
            if (addSelectedVersionsFallbackIfApplicable) {
                const a = this.availableVersions.find(v => !v.label.includes('x-')) ?? this.availableVersions[0];
                if (a) {
                    this.lastOverviewRoute = `/?${QUERY_VERSION}=${a.label}`;
                }
                const addFallBackVersion = !this.selectedErrataIds.length && !this.selectedVersions.length;
                if (addFallBackVersion && a) {
                    this.selectedVersions = [a];
                }
            }
        }
    }
