import Component from 'vue-class-component'
import {RawLocation, Route} from 'vue-router';


// https://class-component.vuejs.org/guide/additional-hooks.html
Component.registerHooks([
    // 'beforeRouteEnter',
    // 'beforeRouteLeave',
    'beforeRouteUpdate',
    'beforeUpdate',
]);

// https://class-component.vuejs.org/guide/hooks-auto-complete.html
declare module 'vue/types/vue' {
    // Augment component instance type
    interface Vue {
        // beforeRouteEnter?(
        //     to: Route,
        //     from: Route,
        //     next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
        // ): void

        // beforeRouteLeave?(
        //     to: Route,
        //     from: Route,
        //     next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
        // ): void

        beforeRouteUpdate?(
            to: Route,
            from: Route,
            next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
        ): void
    }
}



