
    import {Vue, Prop, Watch} from 'vue-property-decorator';
    import Component from 'vue-class-component';


    @Component
    export default class Pagination extends Vue {
        @Prop() value!: number;
        @Prop() totalItems!: number;
        @Prop() pageSize!: number;

        onChange(evt: any): void {
            this.$emit('input', parseInt(evt.target.value, 10));
        }

        get pages(): number {
            return Math.ceil(this.totalItems / this.pageSize);
        }

        onPrevClick() {
            if (this.value > 1) {
                window.scrollTo(0, 0);
                this.$emit('input', this.value - 1);
            }
        }

        onNextClick() {
            if (this.value < this.pages) {
                window.scrollTo(0, 0);
                this.$emit('input', this.value + 1);
            }
        }
    }
