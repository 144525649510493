
    import {Prop, Vue} from 'vue-property-decorator';
    import Component from 'vue-class-component';

    import {SimplifiedFragmentedString} from '@/types.d.ts';
    import {Reference} from '@/errata-x.x.schema';



    @Component
    export default class DeFragment extends Vue {
        @Prop() fragmentedString!: SimplifiedFragmentedString;

        to(id: string) {
            return `/?erratum=${id}`;
        }
    }
