import debounce from 'lodash.debounce';
import {ErratumType, SupportType} from '@/errata-x.x.schema';


export function Debounce(wait: number, options: any = {}) {
    return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        descriptor.value = debounce(descriptor.value, wait, options);
    }
}

const labelsForErratumType = new Map(Object.entries({
    bugfix: 'Bugfix',
    security: 'Security update',
    feature: 'Feature update',
}));

export function labelForErratumType(type: ErratumType) {
    return labelsForErratumType.get(type) ?? "???";
}

const labelsForSupportTypeShort = new Map(Object.entries({
    core: '',
    enterprise: 'Enterprise',
    lts: 'EMS',
}));

export function labelForSupportTypeShort(type: SupportType) {
    return labelsForSupportTypeShort.get(type) ?? "???";
}

const labelsForSupportTypeLong = new Map(Object.entries({
    core: 'All licences',
    enterprise: 'Enterprise licence only',
    lts: 'Extended Maintenance Support only',
}));

export function labelForSupportTypeLong(type: SupportType) {
    return labelsForSupportTypeLong.get(type) ?? "???";
}
