import Vue from 'vue'
import VueRouter, {Route} from 'vue-router'

import ErrataOverview from '@/views/ErrataOverview.vue';


Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        component: ErrataOverview,
    },
];

export const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes,
});